<script setup lang="ts">
export interface Props {
  showSecondColumn: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showSecondColumn: true,
});

</script>

<template>
  <div class="grid w-full grid-cols-1 gap-4 xl:grid-cols-[2fr_1fr] 2xl:grid-cols-[3fr_2fr]">
    <div class="flex h-full grow flex-col rounded-3xl bg-white p-6 shadow-md sm:p-8">
      <slot name="leftCol" />
    </div>
    <div v-if="props.showSecondColumn" class="!mb-0 rounded-3xl bg-blue-50 p-8">
      <slot name="rightCol" />
    </div>
  </div>
</template>
